<template>
  <div id="app">
    <Main v-show="screenWidth >= 1000"/>
    <MainMobile v-show="screenWidth < 1000"/>
    <div class="foot">
      <a class= 'bah' href="https://beian.miit.gov.cn/" target="_blank">
        <img style="margin-bottom: 4px; margin-right: 4px;" src="./assets/batb.png" alt="bah">
        <a class="bah-txt"> 网站备案号: 闽ICP备2022009485号-1</a>
      </a>
      <div style="width:300px;margin:0 auto; padding:20px 0;">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35012102500594" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">闽公网安备 35012102500594号</p></a>
      </div>

    </div>
  </div>
</template>

<script>
import Main from './components/main/index';
import MainMobile from './components/main/index-mobile';

export default {
  name: 'App',
  components: {
    Main,
    MainMobile
  },
  data: () => {
    return {
      screenWidth: document.body.clientWidth,
    }
  },
  mounted() {
    const that = this;
    window.addEventListener("resize", function () {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    });
  },
  watch: {
    screenWidth: {
      immediate: true,
      handler(newVal) {
        console.log(newVal);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /*display: flex;*/
  /*flex: 1;*/
}
.foot {
  display: flex;
  /*background-color: red;*/
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bah {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  /*margin-right: -50px;*/
  /*height: 60px;*/
}
.bah-txt {
  /*font-size: 14px;*/
  /*font-weight: bold;*/
  line-height:20px;
  color:#939393;
}
</style>
