<template>
  <el-container class="root">
    <div class="header">
      <div class="left">
        <div class="hc">
          <div class="logo">
            <img class="logo-img" alt="logo-img" src="../../assets/logo.png">
          </div>
          <div class="title">系统集成项目管理天天练</div>
        </div>
        <div class="desc">
          系统集成项目管理工程师考试助手，每日一练、历年真题、我的错题、我的收藏、答题记录等功能。助你轻松过考！
        </div>
      </div>
      <div class="right">
        <div class="code-img">
          <img class="code" src="../../assets/zl.png" alt="code" >
        </div>
        <div class="code-desc">微信小程序</div>
      </div>
    </div>
    <div class="footer">
      <div class="images">
        <img class="sy" src="../../assets/sy.jpg" alt="sy">
        <img class="sy" src="../../assets/zt.jpg" alt="zt">
      </div>
    </div>
  </el-container>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 60%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  /*background-color: aliceblue;*/
}
.hc {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-content: flex-start;*/
}
.logo {
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.logo-img {
  height: 80px;
  width: 80px;
  /*background-color: #42b983;*/
}
.images {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  /*justify-content: space-around;*/
}
.left {
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right {
  flex: 1;
  padding-left: 20px;
  /*background-color: #42b983;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*width: 200px;*/
}

.title {
  color: white;
  font-weight: bold;
  font-size: 40px;
}

.desc {
  margin-top: 30px;
  color: white;
  font-size: 20px;
  text-align: left;
  width: 90%;
}
.code-img {
  background-color: white;
  /*padding: 20px;*/
  margin-top: 20px;
  /*flex:1;*/
  /*width: 100%;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  overflow: hidden;
  width: 200px;
  height: 200px;
  justify-content: center;
}
.code {
  /*padding: 10px;*/
  width: 180px;
  height: 180px;
  background-color: white;

}
.code-desc {
  margin-top: 15px;
  font-size: 22px;
  color: white;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.sy {
  width: 300px;
  height: 600px;
  margin: 20px;
}
</style>
